<template>
  <div id="app">
    <div class="appContent" v-if="loadingFinished">
      <transition
        name="fade"
        mode="in-out"
        >
          <keep-alive>
            <router-view :key="$route.path">
            </router-view>
          </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      years: ['1893', '1923', '1975', '2020'],
      loadingFinished: false,
      loaderText: 'Application is loading...',
      fadeMode: false,
      rotation: 90
    }
  },
  watch: {
    loadingFinished(newVal) {
      if (newVal) {
        this.$router.push({name: 'home'}).catch((err) => console.info(err));
      }
    }
  },
  async mounted() {
    const body = document.querySelector('body');
    body.style.backgroundColor = 'black';
    const cssSheet = document.styleSheets[0];

    const res = await fetch('https://app01-server.herokuapp.com/rotation')
    const x = await res.json()
    const rotationValue = x.rows[0].rotationValue;
    console.log(rotationValue)

    const leftValue = rotationValue === 270 ? '-120px' : '960px'
    cssSheet.insertRule(`#homepageWrapper, #yearWrapper {left: ${leftValue}}`)

    body.style.transform = `rotate(${rotationValue}deg)`;
    this.loadingFinished = true;
    
  }
} 
</script>

<style>
@import './assets/style.css';

body {
    margin: 0;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
}

.fading {
    opacity: 0.9;
    color: white;
  }

  .appLoader {
    background-color: white;
  }

  .animatedLoad {
    font-size: 25px;
    line-height: 100vw;
    text-align: center;
    transition: all .5s;
    color: white;    
    height: 100vw;
    width: 100vh;
    background-color: black;
  }

</style>
