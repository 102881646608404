<template>
  <div id="homepageWrapper" @click.stop="redirect()">
  </div>
</template>

<script>
export default {
  name: 'homepage',
  methods: {
    redirect() {
      this.$router.push({name: '/1893/false', params: {year: '1893', isFirstLink: true, prevYear: 'false', preload: false}}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                console.info(err);
                }        
            })
    },
  }
}
</script>

<style>

#homepageWrapper {
  background-image: url('../assets/imgs/background.png');
}

.options {
  display: flex;
  position: absolute;
  top: 1570px;
  height: 200px;
  width: calc(100vh - 120px);
  margin: 60px;
}

.options > * {
  width: calc((100vw - 120px)/4);
}
</style>
