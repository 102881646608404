import Vue from 'vue'
import App from './App.vue'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueRouter from 'vue-router';
import IdleVue from 'idle-vue';
import Vuex from 'vuex';
import Homepage from './components/Homepage.vue';
import Yearpage from './components/Yearpage.vue';

Vue.config.productionTip = false
Vue.use(InlineSvgPlugin);
Vue.use(VueRouter);
Vue.use(Vuex);

const routes = [
  {path: '', redirect: '/home'},
  {path: '/home', name: 'home', component: Homepage},
  //{path: '/:year/:prevYear', name: 'yearpage', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1893/false', name: '/1893/false', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1893/1923', name: '/1893/1923', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1893/1975', name: '/1893/1975', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1893/2020', name: '/1893/2020', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1923/1893', name: '/1923/1893', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1923/1975', name: '/1923/1975', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1923/2020', name: '/1923/2020', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1975/1893', name: '/1975/1893', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1975/1923', name: '/1975/1923', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/1975/2020', name: '/1975/2020', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/2020/1893', name: '/2020/1893', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/2020/1923', name: '/2020/1923', component: Yearpage, props: true, meta: { reuse: false }},
  {path: '/2020/1975', name: '/2020/1975', component: Yearpage, props: true, meta: { reuse: false }},
]

const router = new VueRouter({
  routes
});

const store = new Vuex.Store({
  state: {
    language: 'no',
    rotationValue: 90
  },
  mutations: {
    changeLanguage(state) {
      state.language = state.language === 'no' ? 'uk' : 'no';
    },
  },
});

const eventsHub = new Vue();
setTimeout(() => {
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 90000,
    startAtIdle: false
  });
}, 1000);

Vue.mixin({
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  }
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
