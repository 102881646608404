<template>
  <div id="yearWrapper">
  <transition
      name="fade"
      mode="in-out"
      >
      <video v-show="videoConditions" :class="`${year}`"
        id="fullscreenVideo"
        height="1920"
        width="1080"
        muted="muted"
        preload="auto">
        <source :src="videoSrc" type="video/mp4"/>
      </video>
    </transition>
      <div class="options">
        <div v-for="n in years" :key="n" :class="`year${n}`" @click.stop="redirectSpecific(n)"/>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Yearpage',
  props: {
    year: String,
    prevYear: String,
    isFirstLink: Boolean,
    preload: Boolean
  },
  data() {
    return {
      years: ['1893', '1923', '1975', '2020'],
      isVideoFinished: false,
      videoDom: null,
      yearWrapper: null,
    }
  },
  activated() {
    this.isVideoFinished = false;
    if (!this.isFirstLink) this.yearWrapper.style.backgroundImage = `url(${require(`@/assets/imgs/${this.prevYear}.png`)})`;
    else this.yearWrapper.style.backgroundImage = `url(${require(`@/assets/imgs/1893.png`)})`;

    if (!this.isFirstLink && !this.preload) {
      setTimeout(() => {
        this.yearWrapper = document.getElementById('yearWrapper');
        this.videoDom = document.getElementsByTagName('video')[0];
        this.videoDom.play();

        this.videoDom.addEventListener('ended', this.videoFinished);
      }, 1000);
      setTimeout(() => {
        this.yearWrapper.style.backgroundImage = `url(${require(`@/assets/imgs/${this.year}.png`)})`;
      }, 3000);
    }
  },
  deactivated() {
    this.yearWrapper = document.getElementById('yearWrapper');
    this.videoDom = document.getElementsByTagName('video')[0];
    this.videoDom.currentTime = 0;
    
    this.yearWrapper.style.backgroundImage = `url(${require(`@/assets/imgs/${this.year}.png`)})`;
  },
  mounted() {
    this.yearWrapper = document.getElementById('yearWrapper');
    this.yearWrapper.style.backgroundImage = `url(${require(`@/assets/imgs/1893.png`)})`;
    
    if (!this.isFirstLink && !this.preload) {
      setTimeout(() => {
        this.videoDom = document.getElementsByTagName('video')[0];
        this.videoDom.play();

        this.videoDom.addEventListener('ended', this.videoFinished);
        this.yearWrapper.style.backgroundImage = `url(${require(`@/assets/imgs/${this.year}.png`)})`;
      }, 700);
    }
  },
  computed: {
    backgroundCondition() {
      return this.isFirstLink ? this.year : this.prevYear;
    },
    videoSrc() {
      if (!this.isFirstLink) return require(`../assets/imgs/${this.prevYear}-${this.year}.mp4`)
      else return '';
    },
    videoConditions() {
      if (this.isFirstLink) return false;
      else return !this.isVideoFinished;
    }
  },
  watch: {
      isIdle(newVal) {
        if (newVal == true) {
          this.$router.push({name: '/1893/false', params: {year: '1893', isFirstLink: true, prevYear: 'false', preload: false}}).catch((err) => {
                if (err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')) {
                console.info(err);
                }        
            })
            this.$router.push({name: 'home'}).catch((err) => console.info(err));
        }
      },
    },
  methods: {
    redirectSpecific(y) {
      if ((this.isVideoFinished || this.isFirstLink) && y !== this.year) {
        this.$router.push({name: `/${y}/${this.year}`, params: {year: y, prevYear: this.year, preload: false, isFirstLink: false}})
      } 
    },
    videoFinished() {
      this.$nextTick(() => {
        this.isVideoFinished = true;
      })
    }
  }
}
</script>

<style>
</style>
